@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@layer base {
  html {
    font-family: "Roboto Mono", monospace;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

.hide {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
}
